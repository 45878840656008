import React from "react";
import theme from "theme";
import { Theme, Text, Section, Image, Box, Strong, Button, Span } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { IoIosMenu, IoMdMenu } from "react-icons/io";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index111"} />
		<Helmet>
			<title>
				Разработка крутых приложений | Кодовое слово  
			</title>
			<meta name={"description"} content={"Приложения, сайты, чат-боты, автоматизация бизнеса"} />
			<meta property={"og:title"} content={"Наши проекты | Кодовое слово  "} />
			<meta property={"og:description"} content={"Приложения, сайты, чат-боты, автоматизация бизнеса"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/code_word_logo-02-01.png?v=2021-08-10T08:08:05.992Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/favicon.ico?v=2021-08-10T08:08:05.993Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/code_word_logo-02-01.png?v=2021-08-10T08:08:05.992Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/code_word_logo-02-01.png?v=2021-08-10T08:08:05.992Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/code_word_logo-02-01.png?v=2021-08-10T08:08:05.992Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/code_word_logo-02-01.png?v=2021-08-10T08:08:05.992Z"} />
		</Helmet>
		<Section
			width="100wv"
			z-index="12"
			top="0px"
			sm-padding="0px 8px 0px 8px"
			background="--color-light"
			quarkly-title="menu1"
			margin="0 0 px 0"
			min-width="100wv"
			height="40px"
			padding="0px 32px 0px 32px"
			position="sticky"
		>
			<Override
				slot="SectionContent"
				width="100%"
				height="100%"
				min-height="100%"
				min-width="100%"
				color="--dark"
				justify-content="flex-start"
				align-items="flex-start"
			/>
			<Text
				text-align="left"
				align-content="flex-start"
				align-items="center"
				sm-font="--menuMob"
				color="--dark"
				font="--menu"
				display="flex"
				justify-content="flex-start"
			>
				CODE WORD
			</Text>
		</Section>
		<Components.QuarklycommunityKitMobileSidePanel
			sm-z-index="120"
			animDuration="0.5s"
			breakpoint="all"
			z-index="90"
			top="11px"
			position="sticky"
			sm-margin="-34px 0px 0px 0px"
			sm-top="7px"
			margin="-38px 0px 0px 0px"
			menuPosition="full"
			sm-position="sticky"
		>
			<Override
				slot="Button Text"
				sm-width="16px"
				sm-height="16px"
				sm-min-width="16px"
				sm-min-height="16px"
			/>
			<Override slot="Button Text :closed">
				{" "}
			</Override>
			<Override slot="Button Icon :open" icon={IoIosMenu} category="io" />
			<Override
				slot="Button Icon"
				sm-margin="0px 10px 0px 0px"
				category="io"
				icon={IoMdMenu}
				margin="-4px 16px 0px 0px"
				color="--orange"
				size="30px"
			/>
			<Override slot="Overlay" position="sticky" />
			<Override slot="Cross" color="--orange" />
			<Override
				slot="Content :open"
				left="25vw"
				padding="20px 16px 20px 16px"
				sm-min-width="94vw"
				sm-height="100vh"
				hover-width="75vw"
				bottom="0px"
				sm-max-width="94vw"
				sm-width="94vw"
				min-width="75vw"
				height="100vh"
				hover-height="100vh"
				sm-left="6vw"
				sm-top="0px"
				border-color="--color-dark"
				max-width="75vw"
				width="75vw"
			/>
			<Override
				slot="Content"
				sm-height="100vh"
				sm-width="10vw"
				border-color="--color-dark"
				width="10vw"
				height="100vh"
				background="--color-dark"
				left="100vw"
			/>
			<Override slot="Button Icon :closed" icon={IoMdMenu} category="io" color="--dark" />
			<Override slot="Children" width="100%" />
			<Box
				display="flex"
				flex-direction="column"
				justify-content="flex-start"
				height="100%"
				min-height="100%"
			>
				<Box
					sm-width="100%"
					sm-min-width="100%"
					margin="0px 0px 40px 0px"
					height="max-content"
					sm-margin="0px 0px 20px 0px"
				>
					<Box
						quarkly-title="Logo"
						sm-width="100%"
						sm-min-width="100%"
						margin="0px 0px 60px 0px"
						height="170px"
						sm-margin="0px 0px 20px 0px"
					>
						<Image
							width="100%"
							height="170px"
							sm-width="100%"
							sm-display="inline-block"
							sm-height="124px"
							src="https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/00-logo.svg?v=2021-08-06T06:07:23.438Z"
							object-position="0%"
						/>
					</Box>
					<Box height="max-content" display="flex" flex-direction="column" justify-content="flex-start">
						<Box
							min-width="100%"
							quarkly-title="agencyBox"
							margin="0px 0px 11px 0px"
							height="max-content"
							min-height="max-content"
							width="100%"
						>
							<Text
								sm-color="--light"
								sm-font="--text"
								font="--headline3"
								color="--light"
								margin="0px 0px 0px 0px"
								quarkly-title="agencyText"
							>
								Агентство
							</Text>
						</Box>
						<Box
							margin="0px 0px 11px 0px"
							height="max-content"
							min-height="max-content"
							width="100%"
							min-width="100%"
							quarkly-title="servicesBox"
						>
							<Text
								margin="0px 0px 0px 0px"
								quarkly-title="servicesText"
								sm-color="--light"
								sm-font="--text"
								font="--headline3"
								color="--light"
							>
								Услуги
							</Text>
						</Box>
						<Box
							quarkly-title="projectsBox"
							margin="0px 0px 11px 0px"
							height="max-content"
							min-height="max-content"
							width="100%"
							min-width="100%"
						>
							<Text
								color="--light"
								margin="0px 0px 0px 0px"
								quarkly-title="projectsText"
								sm-color="--light"
								sm-font="--text"
								font="--headline3"
							>
								Проекты
							</Text>
						</Box>
						<Box
							min-height="max-content"
							width="100%"
							min-width="100%"
							quarkly-title="articlesBox"
							margin="0px 0px 11px 0px"
							height="max-content"
						>
							<Text
								quarkly-title="articlesText"
								sm-color="--light"
								sm-font="--text"
								font="--headline3"
								color="--light"
								margin="0px 0px 0px 0px"
							>
								Вакансии
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					sm-height="100px"
					flex-direction="column"
					sm-width="max-content"
					sm-display="none"
					height="120px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
				>
					<Text
						font="--headline3"
						color="#ed5d40"
						sm-width="max-content"
						sm-min-width="max-content"
						sm-height="max-content"
						sm-min-height="max-content"
						sm-font="--text"
					>
						Контакты
					</Text>
					<Box
						height="m"
						display="flex"
						align-items="center"
						justify-content="flex-start"
						sm-height="100px"
						width="max-content"
					>
						<Image width="34px" height="34px" src="https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/iconfinder-icon.svg?v=2021-08-06T09:23:38.590Z" margin="0px 12px 0px 0px" />
						<Image margin="0px 12px 0px 0px" width="34px" height="34px" src="https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/iconfinder-icon.svg?v=2021-08-06T09:23:38.590Z" />
					</Box>
				</Box>
			</Box>
		</Components.QuarklycommunityKitMobileSidePanel>
		<Section
			width="100wv"
			min-width="100wv"
			padding="24px 32px 24px 32px"
			z-index="3"
			quarkly-title="Section1"
			height="calc(100vh - 40px)"
			position="sticky"
			top="40px"
			sm-padding="8px 8px 8px 8px"
			background="--color-light"
		>
			<Override
				slot="SectionContent"
				min-width="100%"
				color="--dark"
				justify-content="center"
				width="100%"
				height="100%"
				min-height="100%"
			/>
			<Text
				font="--headline1"
				sm-font="--h1Mob"
				md-font="--h1Mob"
				quarkly-title="h1"
				color="--dark"
			>
				Мы создаем
				<br />
				<Strong
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					color="--orange"
					overflow-wrap="normal"
					word-break="normal"
				>
					крутые digital-продукты
				</Strong>
			</Text>
		</Section>
		<Section
			width="100wv"
			min-width="100wv"
			padding="24px 32px 24px 32px"
			top="40px"
			quarkly-title="Section2"
			border-radius="20px 20px 0px 0px"
			sm-border-radius="15px 15px 0px 0px"
			height="calc(100vh - 40px)"
			min-height="500px"
			background="--color-orange"
			position="sticky"
			z-index="4"
			sm-padding="8px 8px 8px 8px"
		>
			<Override
				slot="SectionContent"
				height="100%"
				min-height="100%"
				min-width="100%"
				color="--dark"
				justify-content="center"
				width="100%"
			/>
			<Text
				font="--headline1"
				color="--light"
				md-font="--h2Mob"
				quarkly-title="description"
				sm-font="--h2Mob"
			>
				Мы превращаем Ваши идеи{" "}
				<br />
				в{" "}
				<Strong
					text-overflow="clip"
					hyphens="manual"
					color="--dark"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
				>
					работающие решения
				</Strong>
			</Text>
		</Section>
		<Section
			sm-border-radius="15px 15px 0px 0px"
			padding="1px 32px 1px 32px"
			position="sticky"
			z-index="12"
			top="40px"
			background="--color-dark"
			quarkly-title="menu2"
			width="100wv"
			min-width="100wv"
			height="40px"
			sm-padding="0px 8px 0px 8px"
			border-radius="20px 20px 0px 0px"
		>
			<Override
				slot="SectionContent"
				justify-content="flex-start"
				align-items="flex-start"
				width="100%"
				height="100%"
				min-height="100%"
				min-width="100%"
				color="--dark"
			/>
			<Text
				justify-content="flex-start"
				text-align="left"
				align-content="flex-start"
				align-items="center"
				sm-font="--menuMob"
				color="--light"
				font="--menu"
				display="flex"
			>
				Работы
			</Text>
		</Section>
		<Section
			min-height="450px"
			position="sticky"
			z-index="4"
			quarkly-title="Section1"
			width="100wv"
			min-width="100wv"
			height="calc(100vh - 80px)"
			padding="24px 32px 24px 32px"
			top="80px"
			sm-padding="8px 8px 8px 8px"
			background="--color-dark"
		>
			<Override
				slot="SectionContent"
				width="100%"
				height="100%"
				min-height="100%"
				min-width="100%"
				color="--dark"
				justify-content="center"
			/>
			<Text
				font="--headline1"
				sm-font="--h1Mob"
				md-font="--h1Mob"
				quarkly-title="h1"
				sm-white-space="inherit"
				color="--light"
			>
				Наши последние
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					color="--orange"
				>
					проекты
				</Strong>
			</Text>
		</Section>
		<Components.CaseProd>
			<Override slot="box" />
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text4" />
			<Override slot="button" />
			<Override slot="sectionOverride" />
			<Override slot="section" />
		</Components.CaseProd>
		<Section
			min-width="100wv"
			position="sticky"
			sm-padding="0px 8px 0px 8px"
			quarkly-title="listingOnMain"
			margin="0 0 30px 0"
			width="100wv"
			height="max-content"
			min-height="max-content"
			padding="0px 32px 0px 32px"
			top="80px"
			z-index="4"
			border-radius="20px 20px 0px 0px"
		>
			<Override
				slot="SectionContent"
				border-radius="20px"
				flex-direction="column"
				width="100%"
				height="100%"
				min-height="100%"
				min-width="100%"
				justify-content="center"
			/>
			<Section
				sm-padding="0 0 0px 0"
				width="100wv"
				position="sticky"
				sm-min-height="calc(100vh - 100px)"
				sm-overflow-y="visible"
				z-index="4"
				md-height="80vh"
				min-height="400px"
				height="calc(100vh - 80px)"
				top="90px"
				margin="0 0 30px 0"
				md-min-height="80vh"
				sm-border-radius="15px"
				max-height="900px"
				sm-max-height="none"
				padding="24px 0 0зч 0"
				sm-height="calc(100vh - 100px)"
				sm-overflow-x="visible"
				quarkly-title="case"
				border-radius="20px"
				min-width="100%"
			>
				<Override
					slot="SectionContent"
					width="100%"
					min-height="100%"
					min-width="100%"
					justify-content="flex-start"
					background="--color-light"
					md-flex-direction="column"
					height="100%"
					border-radius="20px"
					flex-direction="row"
					sm-flex-direction="column"
					sm-overflow-y="hidden"
				/>
				<Image
					sm-height="200px"
					sm-min-height="200px"
					width="50%"
					quarkly-title="photoOnMain1"
					border-radius="20px 0px 0px 20px"
					sm-border-radius="15px 14px 0px 0px"
					md-border-radius="20px 20px 0px 0px"
					margin="0px 0px 0px 0px"
					md-height="300px"
					md-width="100%"
					md-min-height="260px"
					md-margin="0px 0px 0px 0px"
					src="https://images.unsplash.com/photo-1627662168806-efa33a7cda86?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
					object-fit="cover"
					md-min-width="100%"
					sm-width="100%"
					height="100%"
					sm-margin="0px 0px 0px 0px"
				/>
				<Box
					sm-padding="0px 8px 0px 8px"
					margin="0px 0px 0px 0px"
					width="50%"
					sm-height="100%"
					min-height="100%"
					height="100%"
					flex-direction="column"
					sm-min-height="max-content"
					padding="0px 30px 0px 20px"
					md-flex-direction="column"
					sm-width="100%"
					sm-margin="16px 0px 0px 0px"
					md-width="100%"
					display="flex"
					sm-flex-direction="column"
				>
					<Text
						font="--headline2"
						color="--dark"
						display="flex"
						sm-font="--h2Mob"
						quarkly-title="headLine"
						width="100%"
						min-height="m"
						sm-margin="0px 0px 0px 0px"
						md-margin="40px 0px 2px 0px"
						flex-direction="column"
						justify-content="center"
						margin="40px 0px 46px 0px"
						height="max-content"
					>
						Dims Cachback
					</Text>
					<Box
						margin="0px 0px 0px 0px"
						sm-margin="0px 0px 0px 0px"
						width="100%"
						min-height="max-content"
						display="flex"
						flex-direction="row"
						sm-height="max-content"
						md-flex-direction="column"
						height="max-content"
						sm-flex-direction="column"
						sm-min-height="max-content"
						md-margin="20px 0px 0px 0px"
					>
						<Box
							md-min-height="max-content"
							min-height="max-content"
							width="100%"
							height="max-content"
							sm-width="100%"
							sm-min-width="100%"
							sm-min-height="max-content"
							md-height="max-content"
							min-width="100%"
							md-width="100%"
							md-min-width="100%"
							sm-margin="12px 0px 0px 0px"
							sm-height="100%"
						>
							<Text
								font="--headline3"
								color="--dark"
								flex-direction="column"
								sm-font="--headline3Mob"
								sm-margin="0px 0 12px 0"
								display="flex"
								justify-content="center"
								margin="10px 0 0px 0"
								quarkly-title="task"
							>
								Задача
								<br />
							</Text>
							<Text
								display="flex"
								min-height="max-content"
								width="100%"
								min-width="100%"
								sm-font="--leadMob"
								font="--lead"
								color="--dark"
								flex-direction="column"
								justify-content="center"
								margin="4px 0px 0px 0px"
								height="max-content"
								quarkly-title="taskName"
								sm-margin="0px 0px 10px 0px"
							>
								Привлечение клиентов на выдачу кредитов, займов.  клиентов на выдачу кредитов, займов
							</Text>
							<Text
								flex-direction="column"
								sm-font="--headline3Mob"
								font="--headline3"
								color="--dark"
								sm-margin="12px 0 0px 0"
								margin="20px 0 0px 0"
								quarkly-title="solution"
								sm-display="none"
								display="flex"
								justify-content="center"
							>
								Решение
							</Text>
							<Text
								justify-content="center"
								sm-margin="0 0 0px 0"
								margin="4px 0px 30px 0px"
								font="--lead"
								color="--dark"
								flex-direction="column"
								sm-display="none"
								display="flex"
								sm-font="--leadMob"
								quarkly-title="solutionNamr"
							>
								Лендинг, контекстная реклама
							</Text>
							<Button
								display="none"
								margin="30px 0px 0px 0px"
								border-radius="4px"
								sm-margin="12px 0px 0px 0px"
								border-width="1px"
								border-style="solid"
								border-color="--color-orange"
								background="rgba(0, 119, 204, 0)"
								color="--orange"
								font="--base"
							>
								Читать подробнее
							</Button>
						</Box>
					</Box>
				</Box>
			</Section>
		</Section>
		<Components.CaseProd>
			<Override slot="text">
				Mangrove{" "}
			</Override>
		</Components.CaseProd>
		<Components.CaseProd>
			<Override slot="text" margin="40px 0px 46px 0px">
				Vitrina 22|52
			</Override>
		</Components.CaseProd>
		<Section
			z-index="4"
			sm-padding="0px 8px 0px 8px"
			quarkly-title="more"
			min-width="100wv"
			padding="0px 32px 0px 32px"
			position="sticky"
			top="80px"
			sm-display="none"
			display="none"
			width="100wv"
			height="max-content"
			min-height="max-content"
			border-radius="20px 20px 0px 0px"
			margin="0 0 px 0"
		>
			<Override
				slot="SectionContent"
				min-width="100%"
				color="--dark"
				justify-content="center"
				border-radius="20px"
				flex-direction="column"
				width="100%"
				height="100%"
				min-height="100%"
			/>
			<Section
				quarkly-title="more"
				padding="24px 32px 24px 32px"
				z-index="4"
				top="90px"
				min-height="calc(100vh - 110px)"
				border-radius="20px 20px 20px 20px"
				sm-margin="0 0 20px 0"
				min-width="100%"
				height="calc(100vh - 110px)"
				sm-padding="24px 8px 24px 8px"
				sm-height="calc(100vh - 100px)"
				sm-min-height="calc(100vh - 100px)"
				margin="0 0 30px 0"
				width="100%"
				background="--color-orange"
				position="sticky"
			>
				<Override
					slot="SectionContent"
					height="100%"
					color="--dark"
					justify-content="center"
					sm-position="static"
					flex-direction="row"
					align-items="center"
					width="100%"
					min-width="100%"
					md-justify-content="flex-start"
					sm-flex-direction="column"
					min-height="100%"
				/>
				<Box
					display="flex"
					justify-content="center"
					min-width="100%"
					height="100%"
					align-items="center"
					sm-flex-direction="column"
					md-flex-direction="row"
					width="100%"
				>
					<Text
						width="50%"
						min-width="50%"
						md-font="--headline3Mob"
						quarkly-title="process"
						sm-width="100%"
						sm-text-align="center"
						font="--headline1"
						margin="40px 0px 12px 0px"
						sm-font="--headline2"
						height="max-content"
						min-height="max-content"
						color="--light"
					>
						Все наши работы
					</Text>
					<Box
						width="50%"
						display="flex"
						justify-content="center"
						height="50%"
						align-items="center"
					>
						<Image
							width="300px"
							height="300px"
							src="https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/iconfinder-icon.svg?v=2021-08-07T15:26:35.011Z"
							hover-transition="all 0.5s ease-in 0s"
							sm-width="200px"
							sm-height="200px"
							md-height="200px"
							hover-transform="scale(1.1)"
							md-width="200px"
						/>
					</Box>
				</Box>
			</Section>
		</Section>
		<Components.LastForm
			sm-padding="0px 8px 16px 8px"
			height="calc(100vh - 80px)"
			top="80px"
			bottom="auto"
			min-height="calc(100vh - 80px)"
			sm-top="80px"
			margin="30px 0 30px 0"
			padding="0px 32px 16px 32px"
			z-index="4"
			position="sticky"
			sm-position="sticky"
			sm-background="--color-blanc"
		>
			<Override slot="box2" md-width="80%" md-align-items="center" sm-width="100%" />
			<Override slot="box" md-align-items="center" sm-align-items="flex-start" sm-justify-content="flex-start" />
			<Override
				slot="box7"
				min-width="500px"
				max-width="500px"
				md-width="100%"
				md-align-items="center"
			/>
			<Override slot="box1" sm-text-align="left" sm-align-items="flex-start" sm-justify-content="flex-start" />
			<Override slot="text" sm-justify-content="flex-start" sm-text-align="left" />
			<Override slot="box3" max-width="500px" min-width="500px" />
		</Components.LastForm>
		<Section
			height="40px"
			padding="1px 32px 1px 32px"
			sm-padding="1px 8px 1px 8px"
			quarkly-title="menu3"
			min-width="100wv"
			position="sticky"
			z-index="12"
			top="80px"
			background="--color-light"
			border-radius="20px 20px 0px 0px"
			sm-border-radius="15px 15px 0px 0px"
			width="100wv"
		>
			<Override
				slot="SectionContent"
				width="100%"
				height="100%"
				min-height="100%"
				min-width="100%"
				color="--dark"
				justify-content="flex-start"
				align-items="flex-start"
			/>
			<Text
				color="--dark"
				font="--menu"
				display="flex"
				justify-content="flex-start"
				text-align="left"
				align-content="flex-start"
				align-items="center"
				sm-font="--menuMob"
			>
				Услуги
			</Text>
		</Section>
		<Section
			min-width="100wv"
			height="calc(100vh - 120px)"
			padding="24px 32px 24px 32px"
			sm-padding="8px 8px 8px 8px"
			background="--color-light"
			quarkly-title="Section4"
			width="100wv"
			min-height="calc(100vh - 120px)"
			position="sticky"
			z-index="4"
			top="120px"
			sm-background="--color-light"
		>
			<Override
				slot="SectionContent"
				height="100%"
				min-height="100%"
				min-width="100%"
				color="--dark"
				justify-content="flex-start"
				width="100%"
			/>
			<Text
				font="normal 600 90px/0.8 --fontFamily-googleMontserrat"
				sm-font="--h1Mob"
				color="--dark"
				md-font="--h1Mob"
				margin="60px 0px 0px 0px"
				quarkly-title="headline"
			>
				Ваш бизнес
				<br />
				<Strong
					color="--orange"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
				>
					уникален
				</Strong>
			</Text>
			<Text
				min-width="60%"
				sm-font="--leadMob"
				quarkly-title="description"
				color="--dark"
				width="60%"
				sm-width="100%"
				sm-min-width="100%"
				sm-margin="44px 0px 0px 0px"
				md-width="100%"
				font="--text"
				margin="40px 0px 0px 0px"
			>
				Мы знаем это!{" "}
				<br />
				Поэтому мы подбираем решения, которые обеспечат{"  "}
				<Strong
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					sm-font="normal 500 5vw/1.2 --fontFamily-googleMontserrat"
					sm-color="--orange"
					overflow-wrap="normal"
				>
					рост Вашего бизнеса.{" "}
				</Strong>
			</Text>
		</Section>
		<Section
			min-width="100wv"
			height="calc(100vh - 100px)"
			padding="24px 32px 24px 32px"
			z-index="4"
			sm-padding="24px 8px 24px 8px"
			width="100wv"
			position="sticky"
			sm-min-height="calc(100vh - 100px)"
			quarkly-title="serviceAPPS"
			border-radius="20px 20px 0px 0px"
			min-height="calc(100vh - 100px)"
			background="--color-dark"
			top="120px"
			sm-height="max-content"
		>
			<Override
				slot="SectionContent"
				width="100%"
				height="100%"
				min-height="100%"
				min-width="100%"
				color="--dark"
				justify-content="flex-start"
			/>
			<Text
				min-width="600px"
				sm-font="--headline3Mob"
				height="max-content"
				color="--light"
				min-height="max-content"
				sm-margin="16px 0px 16px 0px"
				quarkly-title="headline"
				margin="36px 0px 0px 0px"
				width="600px"
				sm-min-width="100%"
				font="--headline2"
				md-font="--headline2"
				sm-width="100%"
				md-margin="100px 0px 0px 0px"
			>
				Разработка
				<br />
				<Span
					color="--orange"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
				>
					Веб приложений
				</Span>
			</Text>
			<Box
				align-items="flex-start"
				sm-flex-direction="column"
				sm-align-items="flex-start"
				md-flex-direction="column"
				md-align-items="flex-start"
				display="flex"
				flex-direction="column"
			>
				<Text
					sm-width="100%"
					sm-min-width="100%"
					md-width="100%"
					md-min-width="100%"
					color="--light"
					min-width="50%"
					width="75%"
					sm-font="--leadMob"
					sm-margin="0px 0px 0px 0px"
					md-font="--text"
					font="--lead"
					margin="30px 0px 0px 0px"
				>
					Разрабатываем CRM-ститемы, маркет-плейсы, системы складского учета, системы бронирования - проще говоря, все что может запуститься в браузере на ПК или в телефоне.
					<br />
					{" "}Подключаем личные кабинеты, онлайн-оплату, e-mail, sms- оповещения, создание документов.
					<br />
					<br />
					Создаем{" "}
					<Span
						text-overflow="clip"
						hyphens="manual"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
					>
						<Strong
							text-overflow="clip"
							color="--orange"
							hyphens="manual"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
						>
							крутые решения
						</Strong>
					</Span>
					, которые заточены под Ваш бизнес! Без компромиссов и костылей!{" "}
				</Text>
				<Button
					sm-margin="20px 0px 0px 0px"
					background="rgba(0, 119, 204, 0)"
					color="--orange"
					display="none"
					margin="30px 0px 0px 0px"
					height="40px"
					border-width="1px"
					border-style="solid"
					border-color="--color-orange"
					font="--base"
					border-radius="4px"
				>
					Интересно!
				</Button>
			</Box>
		</Section>
		<Section
			quarkly-title="serviceCHATS"
			border-radius="20px 20px 0px 0px"
			width="100wv"
			padding="24px 32px 24px 32px"
			background="--color-light"
			position="sticky"
			height="calc(100vh - 100px)"
			top="120px"
			sm-height="max-content"
			sm-min-height="calc(100vh - 100px)"
			z-index="4"
			sm-padding="24px 8px 24px 8px"
			min-width="100wv"
			min-height="calc(100vh - 100px)"
		>
			<Override
				slot="SectionContent"
				height="100%"
				min-height="100%"
				min-width="100%"
				color="--dark"
				justify-content="flex-start"
				width="100%"
			/>
			<Text
				height="max-content"
				sm-min-width="100%"
				sm-margin="16px 0px 16px 0px"
				font="--headline2"
				min-width="600px"
				min-height="max-content"
				md-font="--headline2"
				md-margin="100px 0px 0px 0px"
				width="600px"
				quarkly-title="headline"
				margin="36px 0px 0px 0px"
				sm-font="--headline3Mob"
				sm-width="100%"
				color="--dark"
			>
				Разработка
				<br />
				<Span
					text-overflow="clip"
					hyphens="manual"
					color="--orange"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
				>
					Чат ботов
				</Span>
			</Text>
			<Box
				justify-content="flex-start"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				sm-flex-direction="column"
				sm-align-items="flex-start"
				md-flex-direction="column"
				md-align-items="flex-start"
			>
				<Text
					md-font="--text"
					md-min-width="100%"
					margin="40px 0px 0px 0px"
					width="75%"
					min-width="75%"
					sm-width="100%"
					sm-min-width="100%"
					font="--lead"
					color="--dark"
					sm-font="--leadMob"
					sm-margin="0px 0px 0px 0px"
					md-width="100%"
				>
					Создаем{" "}
					<Span
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-overflow="clip"
							text-indent="0"
							color="--orange"
							hyphens="manual"
						>
							полезных и незаменимых чат-ботов
						</Strong>
					</Span>
					.{" "}
					<br />
					<br />
					Наши боты умеют:
					<br />
					- продавать  товары и услуги,
					<br />
					- напоминать про просроченные платежи,
					<br />
					- оповещать о задачах,
					<br />
					- отправлять трек-номера доставок,
					<br />
					- принимать платежи.
					<br />
					<br />
					Подключим бота в необходимую соц.сеть или мессенджер
					<br />
					Facebook, Telegram, WhatsApp, Vk
				</Text>
				<Button
					border-style="solid"
					background="rgba(0, 119, 204, 0)"
					margin="30px 0px 0px 0px"
					border-radius="4px"
					height="40px"
					border-width="1px"
					border-color="--color-orange"
					color="--orange"
					font="--base"
					display="none"
				>
					<div display="none" />
					Интересно!
				</Button>
			</Box>
		</Section>
		<Section
			width="100wv"
			min-height="calc(100vh - 130px)"
			background="--color-orange"
			border-radius="20px 20px 0px 0px"
			height="calc(100vh - 120px)"
			padding="24px 32px 24px 32px"
			top="120px"
			sm-height="calc(100vh - 120px)"
			sm-min-height="calc(100vh - 120px)"
			min-width="100wv"
			position="sticky"
			z-index="4"
			sm-padding="24px 8px 24px 8px"
			quarkly-title="serviceAUTO"
		>
			<Override
				slot="SectionContent"
				width="100%"
				height="100%"
				min-height="100%"
				min-width="100%"
				color="--dark"
				justify-content="flex-start"
			/>
			<Text
				quarkly-title="headline"
				margin="36px 0px 0px 0px"
				width="600px"
				height="max-content"
				color="--light"
				sm-min-width="100%"
				md-margin="100px 0px 0px 0px"
				md-font="--headline2"
				sm-width="100%"
				sm-margin="16px 0px 16px 0px"
				min-width="600px"
				sm-font="--headline3Mob"
				min-height="max-content"
				font="--headline2"
			>
				<Span
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					color="--dark"
					overflow-wrap="normal"
				>
					Автоматизация
				</Span>
				{" "}
				<br />
				бизнес-процессов
			</Text>
			<Box
				sm-flex-direction="column"
				sm-align-items="flex-start"
				md-flex-direction="column"
				md-align-items="flex-start"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
			>
				<Text
					sm-width="100%"
					sm-font="--leadMob"
					md-font="--text"
					font="--lead"
					color="#f2f4f6"
					margin="40px 0px 0px 0px"
					width="75%"
					min-width="75%"
					sm-min-width="100%"
					sm-margin="0px 0px 0px 0px"
					md-width="100%"
					md-min-width="100%"
				>
					Убирем рутину из рабочего процесса ваших сотрудников - все что раньше делали люди, теперь делают умные системы.{" "}
					<br />
					<br />
					Сконцентрируйте усилия сотрудников на{" "}
					<Strong
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
					>
						<Span
							text-overflow="clip"
							hyphens="manual"
							sm-font="normal 700 5vw/1.2 --fontFamily-googleMontserrat"
							overflow-wrap="normal"
							word-break="normal"
							text-indent="0"
							color="--dark"
							white-space="normal"
						>
							действительно важных делах
						</Span>
					</Strong>
					, а остальное сделают  наши системы:
					<br />
					- Прием заказов,{" "}
					<br />
					- Создание счетов, отчетов и КП,{" "}
					<br />
					- Проверка статуса оплат по договору,
					<br />
					- Отправка мейлов контрагентам
					<br />
					<br />
					Это и многое другое можно делать на автомате, без участия людей.
				</Text>
				<Button
					border-width="1px"
					border-color="--color-orange"
					border-radius="4px"
					height="40px"
					border-style="solid"
					background="rgba(0, 119, 204, 0)"
					color="--orange"
					font="--base"
					display="none"
					margin="30px 0px 0px 0px"
				>
					Интересно!
				</Button>
			</Box>
		</Section>
		<Section
			min-height="calc(100vh - 100px)"
			padding="24px 32px 24px 32px"
			background="--color-light"
			z-index="4"
			height="calc(100vh - 100px)"
			display="none"
			width="100wv"
			quarkly-title="servicePROMO"
			min-width="100wv"
			position="sticky"
			top="120px"
			sm-height="max-content"
			sm-min-height="calc(100vh - 100px)"
			sm-padding="24px 8px 24px 8px"
			border-radius="20px 20px 0px 0px"
		>
			<Override
				slot="SectionContent"
				width="100%"
				height="100%"
				min-height="100%"
				min-width="100%"
				color="--dark"
				justify-content="flex-start"
			/>
			<Text
				width="600px"
				min-width="600px"
				margin="36px 0px 0px 0px"
				sm-font="--headline3Mob"
				sm-width="100%"
				color="--dark"
				height="max-content"
				min-height="max-content"
				quarkly-title="headline"
				font="--headline2"
				md-font="--headline2"
				sm-min-width="100%"
				md-margin="100px 0px 0px 0px"
				sm-margin="16px 0px 16px 0px"
			>
				Продвижение
				<br />
				<Span
					color="--orange"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
				>
					товаров и услуг
				</Span>
			</Text>
			<Box
				align-items="flex-start"
				sm-flex-direction="column"
				sm-align-items="flex-start"
				md-flex-direction="column"
				md-align-items="flex-start"
				justify-content="flex-start"
				display="flex"
				flex-direction="column"
			>
				<Text
					margin="40px 0px 0px 0px"
					width="75%"
					min-width="75%"
					sm-width="100%"
					sm-font="--leadMob"
					sm-margin="0px 0px 0px 0px"
					font="--lead"
					color="--dark"
					md-min-width="100%"
					md-font="--text"
					md-width="100%"
					sm-min-width="100%"
				>
					Разрабатываем системы для{" "}
					<Strong>
						автоматического создания{" "}
					</Strong>
					рекламных кампаний, креативов.
					<br />
					(контекстная реклама, таргетинговая реклама, рассылки)
					<br />
					Интегрируем все рекламные кабинеты, CRM-систему и складской учет.{" "}
					<br />
					<br />
					Гибкая настройка под реалии вашего бизнеса и ваших клиентов.
				</Text>
				<Button
					background="rgba(0, 119, 204, 0)"
					color="--orange"
					font="--base"
					display="flex"
					margin="30px 0px 0px 0px"
					border-radius="4px"
					border-width="1px"
					border-color="--color-orange"
					height="40px"
					border-style="solid"
				>
					Интересно!
				</Button>
			</Box>
		</Section>
		<Section
			padding="1px 32px 1px 32px"
			width="100wv"
			height="40px"
			z-index="12"
			top="120px"
			sm-padding="1px 8px 1px 8px"
			quarkly-title="menu4"
			border-radius="20px 20px 0px 0px"
			sm-border-radius="15px 15px 0px 0px"
			min-width="100wv"
			position="sticky"
			background="--color-dark"
			sm-margin="20px 0 0 0"
		>
			<Override
				slot="SectionContent"
				justify-content="flex-start"
				align-items="flex-start"
				width="100%"
				height="100%"
				min-height="100%"
				min-width="100%"
				color="--dark"
			/>
			<Text
				text-align="left"
				align-content="flex-start"
				align-items="center"
				sm-font="--menuMob"
				color="--light"
				font="--menu"
				display="flex"
				justify-content="flex-start"
			>
				О студии
			</Text>
		</Section>
		<Section
			width="100wv"
			padding="24px 32px 24px 32px"
			sm-bottom="avto"
			position="sticky"
			background="--color-dark"
			quarkly-title="aboutPreview"
			overflow-y="visible"
			sm-top="avto"
			min-width="100wv"
			min-height="calc(100vh - 160px)"
			top="160px"
			margin="0px 0px 0px 0px"
			z-index="4"
			sm-padding="24px 8px 44px 8px"
			height="calc(100vh - 160px)"
			sm-margin="0px 0px 0px 0px"
		>
			<Override
				slot="SectionContent"
				width="100%"
				height="max-content"
				min-height="max-content"
				min-width="100%"
				color="--dark"
				justify-content="flex-start"
				overflow-y="visible"
			/>
			<Box
				display="flex"
				flex-direction="column"
				justify-content="center"
				height="calc(100vh - 200px)"
				min-height="calc(100vh - 200px)"
			>
				<Text
					sm-width="100%"
					sm-min-width="100%"
					sm-font="--leadMob"
					sm-margin="4px 0px 24px 0px"
					md-font="--lead"
					quarkly-title="description"
					font="--lead"
					width="100%"
					max-width="930px"
					color="--light"
					margin="0px 0px 10px 0px"
				>
					Здесь обычно пишут пафосный текст о дружной команде, разделяющие Ваши 
ценности, команде, которая постоянно развивается, любит и заботится
 о Вас...
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					width="75%"
					min-width="75%"
					sm-width="100%"
					sm-min-width="100%"
					sm-font="--h1Mob"
					sm-margin="4px 0px 0px 0px"
					color="--light"
					quarkly-title="description"
					md-font="--headline3Mob"
					font="--headline2"
				>
					Мы решили
					<br />
					<Strong
						color="--orange"
						sm-font="800 14vw/1.0 --fontFamily-googleMontserrat"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
					>
						{" "}написать правду.
					</Strong>
				</Text>
			</Box>
		</Section>
		<Section
			sm-padding="8px 8px 24px 8px"
			quarkly-title="about"
			sm-margin="0px 0px 0px 0px"
			min-width="100wv"
			position="sticky"
			top="160px"
			margin="0px 0px 0px 0px"
			overflow-y="scroll"
			sm-background="rgba(24, 25, 31, 0)"
			width="100wv"
			min-height="calc(100vh - 160px)"
			height="calc(100vh - 160px)"
			padding="24px 32px 24px 32px"
			z-index="4"
		>
			<Override
				slot="SectionContent"
				min-height="400px"
				justify-content="space-around"
				overflow-y="hidden"
				background="--color-light"
				height="100%"
				color="--dark"
				sm-justify-content="center"
				width="100%"
				border-radius="20px"
				sm-padding="8px 8px 16px 8px"
				sm-height="100%"
				sm-min-height="386px"
				min-width="100%"
				padding="30px 20px 30px 20px"
			/>
			<Box quarkly-title="aboutBox">
				<Text
					margin="0px 0px 0px 0px"
					min-width="75%"
					sm-font="--headline3Mob"
					quarkly-title="headline"
					font="--headline3"
					width="75%"
					sm-width="100%"
					sm-min-width="100%"
					sm-margin="4px 0px 0px 0px"
					md-font="--headline3Mob"
					md-width="100%"
					md-min-width="100%"
					color="--dark"
				>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						color="--orange"
					>
						Мы любим и умеем
					</Strong>
					{" "}создавать сложные продукты!
				</Text>
				<Text
					sm-font="--leadMob"
					md-font="--text"
					quarkly-title="description"
					sm-min-width="100%"
					color="--dark"
					margin="12px 0px 12px 0px"
					width="100%"
					min-width="100%"
					sm-width="100%"
					sm-margin="2vh 0px 3vh 0px"
					font="--lead"
				>
					Именно этим занимаемся уже более 10 лет. Мы работаем только по договору, поэтому{" "}
					<Strong
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
					/>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						color="--orange"
					>
						финансово заинтересованы Вашем успехе
					</Strong>
					.
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
					>
						{" "}
					</Strong>
					Знаем как быстро запустить технически сложные системы.{" "}
				</Text>
			</Box>
			<Box
				quarkly-title="listingPerson"
				sm-height="max-content"
				margin="10px 10px 0px 0px"
				display="flex"
				flex-direction="row"
				overflow-x="scroll"
			>
				<Components.Person />
				<Box
					margin="0px 20px 0px 0px"
					display="flex"
					flex-direction="column"
					width="120px"
					md-width="120px"
					md-min-width="120px"
					align-items="center"
					min-width="120зч"
					quarkly-title="person"
					sm-min-width="100px"
					sm-margin="0px 8px 0px 0px"
					sm-height="116px"
					sm-width="100px"
				>
					<Image
						border-radius="10px"
						width="120px"
						height="120px"
						object-fit="cover"
						min-height="120px"
						md-height="100px"
						sm-min-height="80px"
						src="https://images.unsplash.com/photo-1604560825808-b22cf4fc542d?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						md-width="100px"
						md-min-width="100px"
						md-min-height="100px"
						quarkly-title="photo"
						min-width="120px"
						sm-width="80px"
						sm-height="80px"
						sm-min-width="80px"
					/>
					<Text
						sm-font="--baseMob"
						md-font="--baseMob"
						font="--base"
						min-width="100%"
						sm-height="max-content"
						margin="0px 0px 0px 0px"
						width="100%"
						sm-width="100%"
						sm-margin="2px 0px 0px 0px"
						quarkly-title="name"
						color="--dark"
						sm-min-width="100%"
						text-align="center"
						sm-min-height="max-content"
					>
						Анастасия
					</Text>
					<Text
						min-width="100%"
						sm-font="--baseMob"
						quarkly-title="position"
						font="--text2"
						sm-width="100%"
						sm-margin="2px 0px 0px 0px"
						sm-min-height="max-content"
						margin="0px 0px 0px 0px"
						md-font="--baseMob"
						text-align="center"
						sm-height="max-content"
						color="--dark"
						width="100%"
						sm-min-width="100%"
					>
						программист
					</Text>
				</Box>
				<Box
					sm-margin="0px 8px 0px 0px"
					margin="0px 20px 0px 0px"
					align-items="center"
					md-min-width="120px"
					sm-min-width="100px"
					quarkly-title="person"
					md-width="120px"
					sm-height="116px"
					sm-width="100px"
					display="flex"
					flex-direction="column"
					width="120px"
					min-width="120зч"
				>
					<Image
						width="120px"
						src="https://images.unsplash.com/photo-1625237277864-a6df7a2d72f0?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
						min-height="120px"
						md-min-height="100px"
						sm-width="80px"
						quarkly-title="photo"
						md-min-width="100px"
						sm-height="80px"
						sm-min-width="80px"
						sm-min-height="80px"
						min-width="120px"
						md-width="100px"
						md-height="100px"
						height="120px"
						object-fit="cover"
						border-radius="10px"
					/>
					<Text
						sm-width="100%"
						sm-margin="2px 0px 0px 0px"
						font="--base"
						margin="0px 0px 0px 0px"
						min-width="100%"
						sm-font="--baseMob"
						color="--dark"
						sm-min-width="100%"
						text-align="center"
						sm-min-height="max-content"
						width="100%"
						quarkly-title="name"
						sm-height="max-content"
						md-font="--baseMob"
					>
						Екатерина
					</Text>
					<Text
						text-align="center"
						width="100%"
						sm-min-width="100%"
						sm-margin="2px 0px 0px 0px"
						quarkly-title="position"
						sm-height="max-content"
						sm-min-height="max-content"
						font="--text2"
						color="--dark"
						sm-width="100%"
						sm-font="--baseMob"
						md-font="--baseMob"
						margin="0px 0px 0px 0px"
						min-width="100%"
					>
						Дизайнер
					</Text>
				</Box>
				<Box
					md-min-width="120px"
					sm-min-width="100px"
					sm-margin="0px 8px 0px 0px"
					margin="0px 20px 0px 0px"
					width="120px"
					align-items="center"
					min-width="120зч"
					md-width="120px"
					sm-height="116px"
					display="flex"
					flex-direction="column"
					quarkly-title="person"
					sm-width="100px"
				>
					<Image
						width="120px"
						quarkly-title="photo"
						md-width="100px"
						md-height="100px"
						border-radius="10px"
						min-height="120px"
						sm-min-height="80px"
						src="https://images.unsplash.com/photo-1604560825808-b22cf4fc542d?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						min-width="120px"
						md-min-height="100px"
						sm-height="80px"
						sm-min-width="80px"
						height="120px"
						object-fit="cover"
						md-min-width="100px"
						sm-width="80px"
					/>
					<Text
						color="--dark"
						min-width="100%"
						sm-min-width="100%"
						quarkly-title="name"
						md-font="--baseMob"
						sm-min-height="max-content"
						font="--base"
						margin="0px 0px 0px 0px"
						width="100%"
						sm-height="max-content"
						sm-width="100%"
						sm-font="--baseMob"
						sm-margin="2px 0px 0px 0px"
						text-align="center"
					>
						Анастасия
					</Text>
					<Text
						width="100%"
						sm-min-width="100%"
						sm-height="max-content"
						sm-min-height="max-content"
						font="--text2"
						min-width="100%"
						text-align="center"
						color="--dark"
						sm-margin="2px 0px 0px 0px"
						margin="0px 0px 0px 0px"
						sm-width="100%"
						sm-font="--baseMob"
						md-font="--baseMob"
						quarkly-title="position"
					>
						программист
					</Text>
				</Box>
				<Components.Person />
				<Box
					min-width="120зч"
					md-width="120px"
					md-min-width="120px"
					sm-min-width="100px"
					sm-width="100px"
					sm-margin="0px 8px 0px 0px"
					sm-height="116px"
					margin="0px 20px 0px 0px"
					display="flex"
					flex-direction="column"
					width="120px"
					align-items="center"
					quarkly-title="person"
				>
					<Image
						md-width="100px"
						md-min-width="100px"
						sm-height="80px"
						src="https://images.unsplash.com/photo-1625237277864-a6df7a2d72f0?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
						md-height="100px"
						sm-min-height="80px"
						object-fit="cover"
						quarkly-title="photo"
						min-height="120px"
						md-min-height="100px"
						sm-min-width="80px"
						width="120px"
						height="120px"
						border-radius="10px"
						min-width="120px"
						sm-width="80px"
					/>
					<Text
						width="100%"
						min-width="100%"
						sm-margin="2px 0px 0px 0px"
						font="--base"
						sm-font="--baseMob"
						sm-height="max-content"
						margin="0px 0px 0px 0px"
						sm-width="100%"
						text-align="center"
						color="--dark"
						sm-min-width="100%"
						md-font="--baseMob"
						quarkly-title="name"
						sm-min-height="max-content"
					>
						Екатерина
					</Text>
					<Text
						color="--dark"
						width="100%"
						sm-margin="2px 0px 0px 0px"
						text-align="center"
						sm-width="100%"
						sm-min-width="100%"
						md-font="--baseMob"
						sm-height="max-content"
						sm-min-height="max-content"
						font="--text2"
						sm-font="--baseMob"
						margin="0px 0px 0px 0px"
						min-width="100%"
						quarkly-title="position"
					>
						Дизайнер
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.LastForm
			sm-padding="8px 8px 16px 8px"
			margin="30px 0 0px 0"
			padding="24px 32px 16px 32px"
			z-index="5"
			position="relative"
			top="auto"
			bottom="auto"
		>
			<Override slot="box2" md-width="80%" md-align-items="center" sm-width="100%" />
			<Override slot="box" sm-align-items="flex-start" sm-justify-content="flex-start" md-align-items="center" />
			<Override
				slot="box7"
				md-width="100%"
				md-align-items="center"
				min-width="500px"
				max-width="500px"
			/>
			<Override slot="box1" sm-text-align="left" sm-align-items="flex-start" sm-justify-content="flex-start" />
			<Override slot="text" sm-text-align="left" sm-justify-content="flex-start" />
			<Override slot="box3" max-width="500px" min-width="500px" />
		</Components.LastForm>
		<Components.QuarklycommunityKitPopup />
		<Components.AuthProvider />
		<Components.QuarklycommunityKitCollapse />
		<Components.MenuSiteTop />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"60feb3ae63f5070017af70c4"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<script place={"endOfBody"} rawKey={"619b78f310dffeaeba606725"} />
			<style place={"endOfBody"} rawKey={"619b7917f5e4ef1492682e06"}>
				{"a[href=\"https://quarkly.io/\"]{\ndisplay: none;\n}"}
			</style>
			<script place={"endOfBody"} rawKey={"620d0b13860a3cd523791a26"}>
				{"<!-- Yandex.Metrika counter -->\n<script type=\"text/javascript\" >\n   (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n   m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n   (window, document, \"script\", \"https://mc.yandex.ru/metrika/tag.js\", \"ym\");\n\n   ym(87531028, \"init\", {\n        clickmap:true,\n        trackLinks:true,\n        accurateTrackBounce:true,\n        webvisor:true\n   });\n</script>\n<noscript><div><img src=\"https://mc.yandex.ru/watch/87531028\" style=\"position:absolute; left:-9999px;\" alt=\"\" /></div></noscript>\n<!-- /Yandex.Metrika counter -->"}
			</script>
		</RawHtml>
	</Theme>;
});